import type { DividerProps } from 'antd/es/divider'
import type { FC } from 'react'
import { memo } from 'react'
import { useTheme } from 'styled-components'

import Divider from '@base/Divider'
import {
  TIMETABLE_FORM_DIVIDER_MARGIN_BLOCK,
  TIMETABLE_FORM_HEIGHT,
} from '@widgets/checkout/timetable/components/SearchForm/constants/sizes'

type Props = {
  isWidget?: boolean
}

const FormDivider: FC<DividerProps & Props> = props => {
  const theme = useTheme()

  const baseStyles = {
    borderColor: theme?.colors.fontSecondaryLight,
    height: `${TIMETABLE_FORM_HEIGHT - TIMETABLE_FORM_DIVIDER_MARGIN_BLOCK * 2}px`,
    margin: `${TIMETABLE_FORM_DIVIDER_MARGIN_BLOCK}px 0`,
  }

  const widgetStyles = {
    borderColor: theme?.colors.fontSecondaryLight,
    height: TIMETABLE_FORM_HEIGHT,
    margin: 0,
  }

  return <Divider dashed={false} style={props.isWidget ? widgetStyles : baseStyles} type="vertical" {...props} />
}

export default memo(FormDivider)
