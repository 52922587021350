export enum SubscribeEvents {
  CLEAN_SEARCH_MODAL = 'cleanSearchModal',
  OPEN_ADVANCED_RESERVATION_MODAL = 'openAdvancedReservationModal',
  OPEN_FARE_RULES_MODAL = 'openFareRulesModel',
  OPEN_SEARCH_MODAL = 'openSearchModal',
  OPEN_TRAIN_CARD_GALLERY = 'openTrainCardGallery',
  ORDER_PAGE_INFO_MODAL_OPEN = 'order-page-info-modal-open',
  ORDER_PAGE_MODIFY_POSTPONE = 'order-page-modify-postpone',
  POSTPONE_MODAL_OPEN = 'postpone-modal-open',
  SEARCH_HANDLER = 'searchHandler',
  SEARCH_STATIONS = 'searchStations',
  SHOW_SEARCH_FORM = 'showSearchForm',
}
