import Form from 'antd/es/form'
import dayjs from 'dayjs'
import { useSearchParams } from 'next/navigation'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { selectSearchForms } from '@redux/features/checkout/checkout.selectors'
import { cleanCheckoutOnNewSearch, setTimeTableSort } from '@redux/features/checkout/checkout.slice'
import { OrderBySort, TimetableFieldSort } from '@redux/features/checkout/types/sort'
import { useGetBannerBlockInfoQuery } from '@redux/features/homePage/homePage.api'
import { TRIP_MODE } from '@redux/features/search/constants/search.constants'
import {
  useGetSearchConfigurationQuery,
  useGetSearchFormStationQuery,
  useGetSearchHistoryQuery,
} from '@redux/features/search/search.api'
import { selectDepartureDate, selectRoundTrip } from '@redux/features/search/search.selectors'
import { setWithLayover, switchTripMode } from '@redux/features/search/search.slice'
import { onSearch } from '@redux/features/search/thunks'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { skipToken } from '@reduxjs/toolkit/query/react'

import { SubscribeEvents } from '@Types/common/subscribeEvents'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import { getInitialValues } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/utils/formValues'
import type { SearchFormValuesType } from '@components/mainPage/mainBlock/searchTrains/search/types/formTypes'
import { getStationOptions } from '@components/routesPage/components/bannerWithSearch/utils/getStationOptions'
import { defaultMaxPassengersCount } from '@constants/defaultParameters/searchForm'
import { QueryParameters } from '@constants/routes/queryParameters'
import useIsRoutePage from '@hooks/routes/useIsRoutePage/useIsRoutePage'
import { useDebounce } from '@hooks/useDebounce/useDebounce'
import useGetNodeId from '@hooks/useGetNodeId/useGetNodeId'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import useScrollControl from '@hooks/useScrollControl'
import { usePublish, useSubscribe } from '@hooks/useSubscribe/useSubscribe'
import { mediaQueries } from '@themes/mediaQueries'
import { getInitialValues as getInitialTimetableValues } from '@widgets/checkout/timetable/components/SearchForm/utils/formValues'

type Props = {
  isMainPage?: boolean
  returnIsRequired?: boolean
  skipBannerQuery?: boolean
}

const useTrainSearchForm = ({ isMainPage = true, returnIsRequired, skipBannerQuery = false }: Props) => {
  const dispatch = useAppDispatch()
  const publish = usePublish()
  const params = useSearchParams()
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const isRoutePage = useIsRoutePage()
  const nodeId = useGetNodeId(isMainPage)
  const { data: bannerBlockInfoData } = useGetBannerBlockInfoQuery(nodeId || skipToken, { skip: skipBannerQuery })

  const departureDateInit = useAppSelector(selectDepartureDate)
  const [form] = Form.useForm<SearchFormValuesType>()
  const isRoundTrip = useAppSelector(selectRoundTrip)
  const searchForms = useAppSelector(selectSearchForms)

  const { data: searchConfiguration, isFetching: isFetchingSearchConfiguration } = useGetSearchConfigurationQuery()
  const { data: searchHistory, isFetching: isFetchingSearchHistory } = useGetSearchHistoryQuery(
    !isMainPage || isRoutePage ? skipToken : undefined
  )
  const maxPassengersCount = searchConfiguration?.max_passengers || defaultMaxPassengersCount
  const arrivalStationValue = Form.useWatch(SearchFormKeys.arrival, form)
  const departureStationValue = Form.useWatch(SearchFormKeys.departure, form)
  const children = Form.useWatch(SearchFormKeys.children, { form, preserve: true }) ?? 0
  const childrenAge = Form.useWatch(SearchFormKeys.childrenAge, form)
  const adults = Form.useWatch(SearchFormKeys.adults, { form, preserve: true }) ?? 1
  const date = Form.useWatch(SearchFormKeys.date, form)
  const hasReturn = Array.isArray(date) && !!date[1]
  const layoverValue = Form.useWatch(SearchFormKeys.layover, { form, preserve: true })

  useEffect(() => {
    if (departureDateInit) form.setFieldValue(SearchFormKeys.date, [dayjs(departureDateInit), null])
  }, [departureDateInit, form])

  useEffect(() => {
    dispatch(setWithLayover(!!layoverValue))
  }, [dispatch, layoverValue])

  useEffect(() => {
    if (!returnIsRequired) {
      dispatch(switchTripMode(hasReturn ? TRIP_MODE.ROUND_TRIP : TRIP_MODE.ONE_WAY))
    }
  }, [hasReturn, dispatch, returnIsRequired])

  useEffect(() => {
    publish(SubscribeEvents.SEARCH_STATIONS, {
      [SearchFormKeys.arrival]: arrivalStationValue,
      [SearchFormKeys.departure]: departureStationValue,
      [SearchFormKeys.layover]: layoverValue,
    })
  }, [arrivalStationValue, departureStationValue, layoverValue, publish])

  const [openedPopup, setOpenedPopup] = useState<'calendar' | 'passengers' | undefined>(undefined)
  const debouncedOpenedPopup = useDebounce(openedPopup, 200)
  useScrollControl(isMobile && !!openedPopup)

  const fromQuery = params?.get(QueryParameters.From)
  const toQuery = params?.get(QueryParameters.To)

  const { data: queryStations } = useGetSearchFormStationQuery(
    isMainPage && fromQuery && toQuery ? { from: fromQuery, to: toQuery } : skipToken
  )

  const openPassengers = useCallback(() => setOpenedPopup('passengers'), [])
  const openCalendar = useCallback(() => setOpenedPopup('calendar'), [])
  const closePopup = useCallback(() => setOpenedPopup(undefined), [])

  const onSearchHandler = useCallback(
    (values: SearchFormValuesType) => {
      void dispatch(onSearch(values))
      dispatch(cleanCheckoutOnNewSearch())
      dispatch(setTimeTableSort({ field: TimetableFieldSort.DEPARTURE, orderBy: OrderBySort.ASC }))
    },
    [dispatch]
  )

  const onClearReturnDate = useCallback(() => {
    form.setFieldValue(SearchFormKeys.date, [date[0], null])
  }, [date])

  const arrivalStationInit = useMemo(() => {
    return bannerBlockInfoData && getStationOptions(bannerBlockInfoData.arrival_station)
  }, [bannerBlockInfoData])

  const departureStationInit = useMemo(() => {
    return bannerBlockInfoData && getStationOptions(bannerBlockInfoData.departure_station)
  }, [bannerBlockInfoData])

  useSubscribe(
    SubscribeEvents.SEARCH_HANDLER,
    useCallback(
      ({ formData }: { formData: SearchFormValuesType }) => {
        onSearchHandler(formData)
      },
      [onSearchHandler]
    )
  )

  useEffect(() => {
    const searchFormsLength = searchForms.length

    let initialValues: SearchFormValuesType | void

    if (searchForms.length) {
      searchFormsLength === 2 && !isRoundTrip && dispatch(switchTripMode())
      initialValues = getInitialTimetableValues(searchForms)
    } else {
      initialValues = getInitialValues({
        arrival: queryStations?.arrival || arrivalStationInit || arrivalStationValue,
        departure: queryStations?.departure || departureStationInit || departureStationValue,
        history: searchHistory?.[0],
      })
    }

    form.setFieldsValue(initialValues)
  }, [searchForms, queryStations, searchHistory, arrivalStationInit, departureStationInit])

  return {
    adults,
    arrivalStationValue,
    children,
    childrenAge,
    closePopup,
    date,
    departureStationValue,
    form,
    hasReturn,
    isCalendarOpened: openedPopup === 'calendar',
    isFetching: isFetchingSearchConfiguration || isFetchingSearchHistory,
    isPassengersOpened: openedPopup === 'passengers',
    isRoundTrip,
    layoverValue,
    maxPassengersCount,
    onClearReturnDate: hasReturn ? onClearReturnDate : undefined,
    onSearchHandler,
    openCalendar,
    openedPopup: debouncedOpenedPopup,
    openPassengers,
    searchHistory,
  }
}

export default useTrainSearchForm
