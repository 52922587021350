import { EventEmitter } from 'eventemitter3'
import type { DependencyList } from 'react'
import { useCallback, useEffect } from 'react'

import type { SubscribeEvents } from '@Types/common/subscribeEvents'

const emitter = new EventEmitter()

export const useSubscribe = (event: SubscribeEvents, callback: (...args: any[]) => void, deps?: DependencyList) => {
  const unsubscribe = useCallback(() => {
    emitter.off(event, callback)
  }, [event, callback])

  useEffect(() => {
    emitter.on(event, callback)
    return unsubscribe
  }, [deps])

  return unsubscribe
}

export const usePublish = () => {
  return useCallback((event: string, data?: any) => {
    emitter.emit(event, data)
  }, [])
}
