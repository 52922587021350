import type { StationType } from '@redux/features/routesPage/types/routeInfo'
import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'

export const getStationOptions = (station: StationType): SearchStationsOptionType => {
  return {
    country_code: station.address.country_code,
    geolocation: station.geolocation,
    id: station.id,
    label: station.single_name,
    value: station.uuid,
  }
}
