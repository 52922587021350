import type { FC } from 'react'

import { UilTimes } from '@iconscout/react-unicons'

import { WrapperClearReturnDateButton } from './styles'

type Props = {
  onClear: () => void
}
export const ClearReturnDateButton: FC<Props> = ({ onClear }) => {
  return (
    <WrapperClearReturnDateButton onClick={onClear}>
      <UilTimes />
    </WrapperClearReturnDateButton>
  )
}
