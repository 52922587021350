import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import today from './constants/today'
import isFutureDay from './isFutureDay'
import isFutureDayOrToday from './isFutureDayOrToday'
import isPastDay from './isPastDay'
import isPastDayOrToday from './isPastDayOrToday'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export { today }
export { isFutureDay }
export { isFutureDayOrToday }
export { isPastDay }
export { isPastDayOrToday }
