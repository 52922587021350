import type { RangePickerProps } from 'antd/es/date-picker'
import DatePicker from 'antd/es/date-picker'
import type { FormItemProps } from 'antd/es/form'
import Form from 'antd/es/form'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import { useTranslation } from 'next-i18next'
import type { FC, MouseEvent, ReactNode } from 'react'
import { useCallback, useMemo, useState } from 'react'

import { datePickerButtons } from '@base/DatePicker'
import ClearReturnDateButton from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput/ClearReturnDateButton'
import MobileHeader from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput/mobileHeader/MobileHeader'
import {
  datePikerLabelClassName,
  datePikerPopupClassName,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/classNames'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import {
  formHeightDesktop,
  searchFormSizes,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import SaveButton from '@components/mainPage/mainBlock/searchTrains/search/searchForm/saveButton/SaveButton'
import type { SearchFormValuesType } from '@components/mainPage/mainBlock/searchTrains/search/types/formTypes'
import dateFormats from '@constants/dates/dateFormats'
import useIsMobile from '@hooks/mediaQueries/useIsMobile'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import useToggle from '@hooks/useToggle/useToggle'
import { mediaQueries } from '@themes/mediaQueries'
import confirmButtonClickHandler from '@utils/datePicker/confirmButtonClickHandler'
import getIsDepartureInputActive from '@utils/datePicker/getIsDepartureInputActive'
import getSearchFormPickerInputs from '@utils/datePicker/getSearchFormPickerInputs'
import { isPastDay, today } from '@utils/dateTime/dateValidators'

import { CalenderIcon, DateInputGlobalStyles, DateLabel, WrapperDateInput } from './styles'

dayjs.extend(weekday)
dayjs.extend(localeData)

const ALLOW_EMPTY: RangePickerProps['allowEmpty'] = [false, true]

type Props = {
  close: () => void
  compactLabelView?: boolean
  datePickerProps?: RangePickerProps
  departureLabel?: string
  formItemsProps?: FormItemProps
  isOpened: boolean
  onClearReturnDate?: () => void
  open: () => void
  placeholder?: [string, string]
  position: 'bottom' | 'right'
  returnLabel?: string
}

export const DateInput: FC<Props> = ({
  close,
  compactLabelView,
  datePickerProps,
  departureLabel,
  formItemsProps,
  isOpened,
  onClearReturnDate,
  open,
  placeholder,
  position,
  returnLabel,
}) => {
  const form = Form.useFormInstance<SearchFormValuesType>()
  const [activeInput, setActiveInput] = useState<string>()
  const [tempValue, setTempValue] = useState<SearchFormValuesType[SearchFormKeys.date]>(() =>
    form.getFieldValue(SearchFormKeys.date)
  )
  const { on: isLockOpened, toggleOff: unLockOpened, toggleOn: lockOpened } = useToggle()
  const { t } = useTranslation('Search form')
  const isMobile = useIsMobile()
  const isMobileTablet = useMediaQuery(mediaQueries.mobileTablet)
  const departureCaptionText = departureLabel || t('departureDate')
  const returnCaptionText = returnLabel || t('returnDate')
  const departurePlaceholder = `+${t('departure')}`
  const returnPlaceholder = `+ ${t('addReturn')}`

  const popupClassName = `${datePikerPopupClassName} ${position}`

  const onClickInput = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      const placeholder = (e.target as HTMLInputElement).placeholder

      if (activeInput === placeholder && isOpened) {
        close()
      } else {
        setActiveInput(placeholder)
      }
    },
    [isOpened]
  )

  const onOpenChange = useCallback(
    (value: boolean) => {
      if (value) {
        open()
        lockOpened()
        setTempValue(form.getFieldValue(SearchFormKeys.date) as SearchFormValuesType[SearchFormKeys.date])
      } else if (!isMobile || (isMobile && !isLockOpened)) {
        close()
        unLockOpened()
      }
    },
    [close, open, isLockOpened, isMobile]
  )

  const onPanelClickHandler = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      if (isMobile) return
      const newDate = (e.target as Element).closest('.ant-picker-cell')?.getAttribute('title')

      if (newDate) {
        const isDeparture = getIsDepartureInputActive(getSearchFormPickerInputs())
        const dayjsNewDate = dayjs(newDate)

        form.setFieldValue(
          SearchFormKeys.date,
          isDeparture ? [dayjsNewDate, tempValue[1]] : [tempValue[0], dayjsNewDate]
        )
        setTimeout(() => close(), 300)
      }
    },
    [isMobile, form, tempValue, close]
  )

  const panelRender = useCallback(
    (panel: ReactNode) => (
      <>
        {isMobile && (
          <MobileHeader
            closeAction={() => {
              close()
              //https://firebird.atlassian.net/browse/ITOP-16595
              //I think it antd 5.18.0 RangePicker needConfirm bug https://codepen.io/Sergei-firebird/pen/PovJoRV?editors=001
              setTimeout(() => {
                form.setFieldValue(SearchFormKeys.date, tempValue)
              }, 0)
            }}
          />
        )}
        <div onClick={onPanelClickHandler} style={{ height: 'inherit', width: 'inherit' }}>
          {panel}
        </div>
      </>
    ),
    [isMobile, onPanelClickHandler, close, tempValue]
  )

  const renderExtraFooter = useCallback(
    () => (
      <SaveButton
        onClick={e => {
          confirmButtonClickHandler(e)
          unLockOpened()
          close()
        }}
      />
    ),
    []
  )

  const style = useMemo(
    () => ({
      height: `${isMobile ? searchFormSizes.fieldHeightMobile : searchFormSizes.fieldHeightDesktop}px`,
      padding: 0,
      width: '100%',
    }),
    [isMobile]
  )

  const popupStyle = useMemo(
    () => ({
      height: `${isMobile ? '100%' : `${formHeightDesktop + searchFormSizes.layoversHeightDesktop}px`}`,
      padding: 0,
      position: isMobile ? 'fixed' : 'absolute',
      width: `${
        isMobile
          ? '100%'
          : isMobileTablet
          ? `${searchFormSizes.popupWidthTablet}px`
          : `${searchFormSizes.popupWidthDesktop}px`
      }`,
      ...datePickerProps?.popupStyle,
    }),
    [isMobile, isMobileTablet, datePickerProps?.popupStyle]
  )

  const suffixIcon = useMemo(() => (compactLabelView ? null : <CalenderIcon />), [compactLabelView])

  return (
    <WrapperDateInput>
      <DateLabel
        $compactLabelView={compactLabelView}
        $departureLabel={departureCaptionText}
        $isPopoverOpened={isOpened}
        $returnLabel={returnCaptionText}
        className={datePikerLabelClassName}
        name={SearchFormKeys.date}
        rules={[{ required: true }]}
        {...(formItemsProps || {})}
      >
        <DatePicker.RangePicker
          {...datePickerButtons}
          allowClear={false}
          allowEmpty={ALLOW_EMPTY}
          autoFocus={true}
          clearIcon={false}
          disabledDate={isPastDay}
          format={dateFormats['01 Jan 2023']}
          getPopupContainer={node => node}
          inputReadOnly={true}
          minDate={today}
          needConfirm
          onClick={onClickInput}
          onOpenChange={onOpenChange}
          open={isOpened}
          panelRender={panelRender}
          placeholder={placeholder ? placeholder : [departurePlaceholder, returnPlaceholder]}
          placement="bottomLeft"
          popupClassName={popupClassName}
          renderExtraFooter={renderExtraFooter}
          separator={null}
          showNow={false}
          style={style}
          suffixIcon={suffixIcon}
          variant="borderless"
          {...(datePickerProps || {})}
          popupStyle={popupStyle as RangePickerProps['popupStyle']}
        />
      </DateLabel>
      {onClearReturnDate && <ClearReturnDateButton onClear={onClearReturnDate} />}
      <DateInputGlobalStyles />
    </WrapperDateInput>
  )
}
